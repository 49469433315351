import { cache, createAsync, useParams } from "@solidjs/router";
import { Show } from "solid-js";
import ClientOnlyComponent, {
  ClientComponent,
} from "~/client_only_components/client_component";
import { PlutoMyGiftCardsPageComponent } from "~/components/gifting/pluto-receiver/pluto-receiver-my-gift-cards/pluto_my_gift_cards";
import { getPlutoProfileRouteData } from "~/server/data/personalised_gifting/pluto_profile_route_data";

export default function BrandCollectionProfileRoute() {
  const ProfileRouteData$C = cache(
    getPlutoProfileRouteData,
    "getPlutoProfileRouteData"
  );

  const params = useParams();
  const giftingKey = params.giftingKey$ as string;

  const routeData = createAsync(
    async () => {
      return await ProfileRouteData$C({
        giftingKey,
      });
    },
    { deferStream: true }
  );

  return (
    <>
      <ClientOnlyComponent component={ClientComponent.SnackbarHost} />

      <Show when={routeData()}>
        <PlutoMyGiftCardsPageComponent
          cardList={routeData()!.cardsList}
          coinsCount={routeData()!.coinsCount}
          giftingKey={giftingKey}
          phoneNumber={routeData()!.phoneNumber ?? ""}
          logoUrl={routeData()!.logoUrl}
        />
      </Show>
    </>
  );
}
